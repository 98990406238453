import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import "../css/Slide.css";

import { green } from "@mui/material/colors";
// import required modules
import { Autoplay, EffectCoverflow, Pagination } from "swiper";


import img1 from '../images/image6.jpg';
import img6 from '../images/n1.jpg';
import img5 from '../images/n5.jpg';
import img2 from '../images/image2.jpeg';
import img7 from '../images/image1.jpeg';
import img4 from '../images/image4.jpeg';
import img3 from '../images/image3.jpeg';



const Slide = () => {
    let data = [
        {
            id: 1,
            imgSrc: img1,
           
        },
        {
            id: 2,
            imgSrc: img2,
         
        },
        {
            id: 3,
            imgSrc: img3,
         
        },
        {
            id: 4,
            imgSrc: img4,
           
        },
        {
            id: 5,
            imgSrc: img5,
           
        },
        {
            id: 6,
            imgSrc: img6,
           
        },
        {
            id: 7,
            imgSrc: img7,
           
        },
    ]
    return (
        <>
<section id="gallery" className="text-center container  mb-2">
               <h1 className=" pt-4 mb-2" style={{ color: '#4F4F4F' }}>Gallery</h1> 
            <div className="slider mb-4 pb-4">
                <Swiper
                    effect={"coverflow"}
                    grabCursor={true}
                    centeredSlides={true}
                    slidesPerView={"auto"}
                    coverflowEffect={{
                        rotate: 50,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: true,
                    }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={true}
                    modules={[Autoplay, EffectCoverflow, Pagination]}
                    className="mySwiper"
                >
                    {data.map((item, index) => {
                        return (<>
                       

                            <SwiperSlide>
                                

                                    <img src={item.imgSrc} />
                               
                                    
                              

                               

                            </SwiperSlide>


                    </>
                        )
                    })}

                </Swiper></div>
                </section>
        </>
    );
}

export default Slide;