import restaurant from "./images/logoev.jpeg";
import img_card from "./images/noi.jpg";
import imgheaderrestaurant from "./images/suflet.jpeg";
import imgheader from "./images/h1.jpg";
import imgheaderm from "./images/h1.jpg";
import img2 from "./images/dress3.png";
import imgheadermiini from "./images/restaurant1.jpeg";
import imgheadermiinimb from "./images/restaurant1.jpeg";

{/*valeriacosarca@gmail.com*/}

const data = {
    introData: [{
        title: "Invitatie Online",
        mire: "Radu",
        mireasa: "Mariana",
        data: "28 august 2023",
        data_confirmare: "5 august 2023",
        savedata: "~ Salvează Data ~",
        imgdesktop: imgheader,
        imgmobil: imgheaderm,
        email: "marianastavila5@gmail.com", 
       tel: "+37368034719",
       phone: "tel:+37368034719",
       viber: "viber://chat?number=%2B37368034719",
       whatsapp: "https://wa.me/+37368034719",
       messenger: "https://www.messenger.com/t/mariana.stavila.92",
       tel1: "+373xxx",
       phone1: "tel:+373xxx",
       viber1: "viber://chat?number=%2B373xxx",
       whatsapp1: "https://wa.me/+373xxx",
       messenger1: "https://www.messenger.com/t/iurii.denis",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Locația Evenimentului",
            name: "Lago Event Park, Sala Portofino",
            data: "28 august 2023, luni, ora 17:00",
            adress: "Dănceni 6814",
            harta: "https://goo.gl/maps/ABCEUo4Y4YJ7wJsw9",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10893.142391203228!2d28.7268806!3d46.9560784!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xcc54487a6e517bd4!2sLago%20Event%20Park!5e0!3m2!1sro!2s!4v1675293360359!5m2!1sro!2s" 
        }

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Ne căsătorim!",
            title2: "VĂ INVITĂM LA NUNTA NOASTRĂ!",
            message: "În ziua în care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
            ps: "P.S.: Am aprecia enorm dacă a-ți înlocui florile cu o carte sau o sticlă de vin.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img2,
            title1: "Dress code:",
            title2: "BLACK STYLE",
            message: "Vom aprecia respectarea codului vestimentar.",
            message2: "" ,
            message1: "Mulțumim!" 
        }
    ],
    blogDataOmSuflet: [
        {

          /*  img: imgheaderrestaurant,*/
            title: "NAȘII SUNT SINGURA FAMILIE PE CARE O ALEGI ȘI NOI I-AM ALES PE CEI MAI BUNI!",
            message: "Ne vor susține și ne vor îndruma:",
            nasii: "NAȘII",
            nasii_nume: "Ion & Svetlana",
        }
    ],
    blogDataDrum: [
        {
            img: imgheadermiini,
            title: "Nu uitați să luați cu voi un car de voie bună și opinci de dansat!!!",
            message: "P.S: Vom fi recunoscători pentru confirmarea prezenței DVS!",
        }
    ],

}

export default data;